import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Link } from "gatsby";
import { FaPlane } from "react-icons/fa";
import { RiTruckFill, RiShip2Fill } from "react-icons/ri";

import Paginate from "../../../../../../components/Paginate/Paginate";
import Table from "../../../../../../components/styles/Table";
import requests from "../../../../../../services/requests";

import { colors } from "../../../../../../assets/colors";

import Modal from "../../../../../../components/Modals";
import NewBudgetForm from "../../../../../cotacao/components/NewBudgetForm";

function QuotesTable({ process }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentQuote, setCurrentQuote] = useState({});
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    const loadList = () => {
        //Get Data
        requests.getProcessQuote(process.identifier).then(response => {
            setData(response.data);
            setLoading(false);

            setMeta(response.meta);
            setLoading(false);
        });
    };

    useEffect(() => {
        loadList();
    }, [action]);

    const [openNewModal, setOpenNewModal] = useState(false);
    const actionNewModal = () => {
        setOpenNewModal(!openNewModal);
    };

    const [openEditModal, setOpenEditModal] = useState(false);
    const actionEditModal = () => {
        setOpenEditModal(!openEditModal);
    };

    return (
        <>
            <Modal
                onClose={actionNewModal}
                size="xs:w-11/12 lg:w-10/12 min-h-80%"
                show={openNewModal}
                title={<h4>NOVO ORÇAMENTO</h4>}
            >
                <NewBudgetForm
                    process={process}
                    onSuccess={() => {
                        actionNewModal();
                        loadList();
                    }}
                />
            </Modal>

            <Modal
                onClose={actionEditModal}
                size="xs:w-11/12 lg:w-10/12 min-h-80%"
                show={openEditModal}
                title={<h4>ORÇAMENTO - {currentQuote.name}</h4>}
            >
                {/*<EditBudgetForm
                    setCurrentQuote={setCurrentQuote}
                    quote={currentQuote}
                    closeModal={actionEditModal}
                    onSuccess={() => {
                        loadList();
                    }}
                />*/}
            </Modal>

            <article>
                <div className="flex mt-3 justify-between ">
                    <h5>ORÇAMENTOS DO PROCESSO</h5>
                    <button
                        onClick={actionNewModal}
                        className="inline-block float-right bg-roxo_oficial rounded-md my-1 text-white outline-none p-2"
                    >
                        <span>Novo orçamento</span>
                    </button>
                    {/* <ModalQuote process={process} onSuccess={() => {
                        loadList();
                    }} /> */}
                </div>
                {loading ? (
                    <div>
                        <ClipLoader size={20} loading={true} />
                    </div>
                ) : (
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Tipo</th>
                                <th>Carga disponível</th>
                                <th>Origem</th>
                                <th>Destino</th>
                                <th>Valor invoice</th>
                                <th>Solicitado por</th>
                                <th>Status</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(quote => (
                                <tr key={quote.identifier}>
                                    <td>{quote.name}</td>
                                    <td>
                                        {quote.modality.slug === "air" && (
                                            <FaPlane
                                                className={`text-roxo_oficial fill fill-current w-6 h-4 bg-white px-1 mb-1`}
                                            />
                                        )}
                                        {quote.modality.slug === "sea" && (
                                            <RiShip2Fill
                                                className={`text-roxo_oficial fill fill-current w-6 h-4 bg-white px-1 mb-1`}
                                                color={colors.roxo_oficial}
                                            />
                                        )}
                                        {quote.modality.slug === "ground" && (
                                            <RiTruckFill
                                                className={`text-roxo_oficial fill fill-current w-6 h-4 bg-white px-1 mb-1`}
                                                color={colors.roxo_oficial}
                                            />
                                        )}
                                    </td>
                                    <td>{quote.availableDateFormatted}</td>
                                    <td>
                                        {quote.originPort?.name} ({quote.originPort?.country?.name})
                                    </td>
                                    <td>
                                        {quote.destinationPort?.name} ({quote.destinationPort?.country?.name})
                                    </td>
                                    <td>{quote.invoiceValue}</td>
                                    <td>{quote.requestedPeople.name}</td>
                                    <td>{quote.statusReadable}</td>
                                    <td>
                                        <Link
                                            to={`/cotacao/detalhes/${quote?.identifier}`}
                                            state={{ prevPath: window.location.pathname, data: quote }}
                                        >
                                            <button className="bg-C1 rounded-md my-1 text-roxo_oficial outline-none hover:bg-C3 p-2 w-24">
                                                <span>Visualizar</span>
                                            </button>
                                        </Link>
                                        {/*<button
                                            onClick={() => {
                                                setCurrentQuote(quote);
                                                actionEditModal();
                                            }}
                                            className="bg-C1 rounded-md my-1 text-roxo_oficial outline-none hover:bg-C3 p-2 w-24">
                                            <span>Visualizar</span>
                                        </button>*/}
                                    </td>
                                </tr>
                            ))}
                            {(!data || data.length === 0) && (
                                <tr className="text-center">
                                    <td className="text-center" colSpan="10">
                                        Sem orçamentos neste processo.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}
                {meta && meta.total_pages > 1 && (
                    <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                )}
            </article>
        </>
    );
}

export default QuotesTable;
